import React, { useState } from 'react';
import {
	Page1,
	Page2,
	Page3,
	Page4,
	Page5,
	Page6,
	Page7,
	Page8,
	Page9,
	UploadVideo,
} from '.';

let lvl = 1;
const Main = () => {
	const [stages, setStages] = useState([
		true,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	]);
	// Admin key next is 'n'

	const skipLevel = () => {
		lvl++;
		updateStages(lvl, true);
	};
	const updateStages = (index, value) => {
		const updatedStages = [...stages];
		updatedStages[index] = value;
		setStages(updatedStages);
	};

	return (
		<div>
			<button className='admin-btn' onClick={skipLevel}>
				Next Admin btn
			</button>

			{/* <Page4 /> */}
			{/* <UploadVideo /> */}
			{stages[9] ? (
				<UploadVideo />
			) : stages[8] ? (
				<Page9 updateStages={updateStages} />
			) : stages[7] ? (
				<Page8 updateStages={updateStages} />
			) : stages[6] ? (
				<Page7 updateStages={updateStages} />
			) : stages[5] ? (
				<Page6 updateStages={updateStages} />
			) : stages[4] ? (
				<Page5 updateStages={updateStages} />
			) : stages[3] ? (
				<Page4 updateStages={updateStages} />
			) : stages[2] ? (
				<Page3 updateStages={updateStages} />
			) : stages[1] ? (
				<Page2 updateStages={updateStages} />
			) : (
				<Page1 updateStages={updateStages} />
			)}
		</div>
	);
};

export default Main;
