import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import QrScanner from '../components/QrScanner';

const Page6 = ({ updateStages }) => {
	const qrCode = 'https://qrcodes.pro/0Oj4dB';

	const [videoEnd, setVideoEnd] = useState(false);

	const handleVideoEnd = () => {
		setVideoEnd(true);
	};

	const handleScan = (scanComplete) => {
		if (scanComplete) {
			updateStages(6, true);
		}
	};
	return (
		<div className='container'>
			<h3>סרטון של סכסוך ישראלי פלסטיני</h3>
			<div className='player-wrapper'>
				<ReactPlayer
					url='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/videos/page6.mp4'
					width='80%'
					height='80%'
					controls
					onEnded={handleVideoEnd}
				/>
			</div>
			{videoEnd ? <QrScanner handleScan={handleScan} qrCode={qrCode} /> : ''}
		</div>
	);
};

export default Page6;
