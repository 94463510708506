import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import VerificationInput from 'react-verification-input';
import { NotificationManager } from 'react-notifications';

const Page4 = ({ updateStages }) => {
	const pinCode = '1948';
	const [userPinCode, setUserPinCode] = useState('');
	const [videoEnd, setVideoEnd] = useState(false);

	useEffect(() => {
		if (userPinCode.length === pinCode.length) {
			if (userPinCode === pinCode) {
				NotificationManager.success(
					'הצלחתם ועברתם לשלב הבא',
					'גאונים!',
					1500,
					setTimeout(() => {
						updateStages(4, true);
					}, 1000)
				);
			} else {
				NotificationManager.error(
					'המחאה נגד ישראל צוברת תאוצה - מהרו לגלות את התשובה הנכונה!',
					'',
					1500
				);
				setUserPinCode('');
			}
		}
	}, [userPinCode]);

	const handleVideoEnd = () => {
		setVideoEnd(true);
	};
	const handlePinCodeChange = (event) => {
		setUserPinCode(event);
	};

	return (
		<div className='container'>
			<h2> סרטון תדמית ישראל בעולם</h2>
			<div className='player-wrapper'>
				<ReactPlayer
					url='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/videos/page4.mp4'
					width='80%'
					height='80%'
					controls
					onEnded={handleVideoEnd}
				/>
			</div>
			{videoEnd && (
				<div className='ltr'>
					<VerificationInput
						validChars='0-9'
						inputProps={{ inputMode: 'numeric' }}
						value={userPinCode}
						onChange={handlePinCodeChange}
						length={4}
					/>
				</div>
			)}
		</div>
	);
};

export default Page4;
