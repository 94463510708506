import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import ButtonContinue from '../components/ButtonContinue';

const Page2 = ({ updateStages }) => {
	const [videoEnd, setVideoEnd] = useState(false);

	const handleVideoEnd = () => {
		setVideoEnd(true);
	};
	const handleButtonClick = () => {
		updateStages(2, true);
	};

	return (
		<div className='container'>
			<h2>עליכם לצפות בסרטון הבא </h2>
			<div className='player-wrapper'>
				<ReactPlayer
					url='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/videos/page2.mp4'
					width='70%'
					height='70%'
					controls
					onEnded={handleVideoEnd}
				/>
			</div>
			{videoEnd && (
				<ButtonContinue handleButtonClick={handleButtonClick} text='המשך' />
			)}
		</div>
	);
};

export default Page2;
