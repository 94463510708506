import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import VerificationInput from 'react-verification-input';
import { NotificationManager } from 'react-notifications';

const Page7 = ({ updateStages }) => {
	const pinCode = '1937';

	const [userCode, setUserCode] = useState('');
	const [videoEnd, setVideoEnd] = useState(false);

	useEffect(() => {
		if (userCode.length === pinCode.length) {
			if (userCode === pinCode) {
				NotificationManager.success(
					'הצלחתם ועברתם לשלב הבא',
					'גאונים!',
					1500,
					setTimeout(() => {
						updateStages(7, true);
					}, 1000)
				);
			} else {
				NotificationManager.error(
					'המחאה נגד ישראל צוברת תאוצה - מהרו לגלות את התשובה הנכונה!',
					'',
					1500
				);
				setUserCode('');
			}
		}
	}, [userCode]);

	const handleVideoEnd = () => {
		setVideoEnd(true);
	};
	const handleChange = (event) => {
		setUserCode(event);
	};

	return (
		<div className='container'>
			<h2> סרטון שאלות קשות</h2>
			<div className='player-wrapper'>
				<ReactPlayer
					url='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/videos/page7.mp4'
					width='80%'
					height='80%'
					controls
					onEnded={handleVideoEnd}
				/>
			</div>
			{videoEnd && (
				<div className='ltr'>
					<VerificationInput
						validChars='0-9'
						inputProps={{ inputMode: 'numeric' }}
						value={userCode}
						onChange={handleChange}
						length={4}
					/>
				</div>
			)}
		</div>
	);
};

export default Page7;
