import Main from './Pages/Main';
import './Styles/app.css';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

function App() {
	return (
		<div className='App'>
			<NotificationContainer />
			<div className='main'>
				<img
					className='logo'
					src='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/logo-white.png'
					alt=''
				/>
				<Main />
			</div>
		</div>
	);
}

export default App;
