import AWS from 'aws-sdk';

import { useState } from 'react';

function UploadVideo() {
	const [file, setFile] = useState(null);
	const [uploadBar, setUploadBar] = useState('');

	const uploadFile = async () => {
		if (!file) return alert('File not exist.');
		else {
			const S3_BUCKET = 'tevelbreakoutgame';
			// const REGION = 'region';

			AWS.config.update({
				accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
				secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
			});
			const s3 = new AWS.S3({
				params: { Bucket: S3_BUCKET },
				// region: REGION,
			});

			const params = {
				Bucket: S3_BUCKET,
				Key: `usersUpload/${Date.now()}-${file.name}`,
				Body: file,
			};

			var upload = s3
				.putObject(params)
				.on('httpUploadProgress', (evt) => {
					setUploadBar(parseInt((evt.loaded * 100) / evt.total) + '%');
					console.log(
						'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%'
					);
				})
				.promise();
		}
	};

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		const allowedTypes = ['video/mp4', 'video/mov', 'video/avi'];
		if (selectedFile && allowedTypes.includes(selectedFile.type)) {
			setFile(selectedFile);
		} else {
			alert('Please select a valid video file (mp4, mov, avi)');
		}
	};

	return (
		<div>
			<input
				type='file'
				accept='video/*'
				capture='user'
				onChange={handleFileChange}
			/>
			<button onClick={uploadFile}>Upload</button>
			{uploadBar && <div>{uploadBar}</div>}
		</div>
	);
}

// function UploadVideo() {
// 	const [file, setFile] = useState(null);
// 	const [uploadBar, setUploadBar] = useState('');

// 	const uploadFile = async () => {
// 		if (!file) return alert('File not exist.');
// 		else {
// 			const S3_BUCKET = 'tevelbreakoutgame';
// 			// const REGION = 'region';

// 			AWS.config.update({
// 				accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
// 				secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
// 			});
// 			const s3 = new AWS.S3({
// 				params: { Bucket: S3_BUCKET },
// 				// region: REGION,
// 			});

// 			const params = {
// 				Bucket: S3_BUCKET,
// 				Key: `usersUpload/${Date.now()}-${file.name}`,
// 				Body: file,
// 			};

// 			var upload = s3
// 				.putObject(params)
// 				.on('httpUploadProgress', (evt) => {
// 					setUploadBar(parseInt((evt.loaded * 100) / evt.total) + '%');
// 					console.log(
// 						'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%'
// 					);
// 				})
// 				.promise();

// 			await upload.then((err) => {
// 				console.log(err);
// 				alert('File uploaded successfully.');
// 			});
// 		}
// 	};

// 	const handleFileChange = (e) => {
// 		const file = e.target.files[0];
// 		if (!file) {
// 			return alert('File not exist.');
// 		} else {
// 			setFile(file);
// 		}
// 	};
// 	return (
// 		<div className='App'>
// 			<div>
// 				<input type='file' onChange={handleFileChange} />
// 				<button onClick={uploadFile}>Upload</button>
// 				<span>{uploadBar}</span>
// 			</div>
// 		</div>
// 	);
// }

export default UploadVideo;
