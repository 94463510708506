import React, { useState } from 'react';
import '../Styles/popup.css';
import ButtonContinue from '../components/ButtonContinue';
const Page1 = ({ updateStages }) => {
	const messages = [
		[
			'ברוכים הבאים ל"שגרירי תבל: ניווט במבוך הדיפלומטי של ישראל". חווית חדר בריחה שמכניסה אותך לרשת הסבוכה של הדיפלומטיה המזרח תיכונית.',
			'כשתעברו את הסף, תמצאו את עצמכם שקועים בעולם שבו נרטיבים בני אלפי שנים נאבקים על דעת הקהל העולמית העכשווית.',
		],
		[
			'המשימה? לנווט בסדרה של חידות המשקפות את המורכבות של הדיפלומטיה הישראלית והאתגרים העומדים בפניה היום.',
			'התכוננו לצעוד על הגבול הדק שבין פוליטיקה והיסטוריה, תרבות ואסטרטגיה, להכיר את הטכניקות החשובות ביותר לייצוג מדינת ישראל בעולם ולקחת חלק פעיל בדיפלומטיה ציבורית בעצמכם.',
		],
		[
			'אבל זכרו - תקתוקו של השעון הוא תזכורת מתמדת לכך שהדינמיקה העולמית לא מחכה לאף אחד.',
			'הבחירות וההחלטות ישקפו את האיזון העדין הנדרש מכל אחד ואחת המייצגים את מדינת ישראל בעולם.',
		],
	];
	const [displayMsg, setDisplaymsg] = useState(0);

	const handleButtonClick = () => {
		if (displayMsg < messages.length - 1) {
			setDisplaymsg(displayMsg + 1);
		} else {
			updateStages(1, true);
		}
	};

	return (
		<div className='popup-container'>
			<div className='popup-box'>
				<div className='popup-bubble right'>{messages[displayMsg][0]}</div>
				<div className='popup-bubble left'>{messages[displayMsg][1]}</div>

				{displayMsg === messages.length - 1 ? (
					<ButtonContinue handleButtonClick={handleButtonClick} text='התחל' />
				) : (
					<ButtonContinue handleButtonClick={handleButtonClick} text='המשך' />
				)}
			</div>
		</div>
	);
};

export default Page1;
