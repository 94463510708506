import React, { useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';
import { NotificationManager } from 'react-notifications';

const Page3 = ({ updateStages }) => {
	const textCode = '32571984';
	const [userTextCode, setUserTextCode] = useState('');

	useEffect(() => {
		if (userTextCode.length === textCode.length) {
			if (userTextCode === textCode) {
				NotificationManager.success(
					'הצלחתם ועברתם לשלב הבא',
					'גאונים!',
					1500,
					setTimeout(() => {
						updateStages(3, true);
					}, 1000)
				);
			} else {
				NotificationManager.error(
					'המחאה נגד ישראל צוברת תאוצה - מהרו לגלות את התשובה הנכונה!',
					'',
					1500
				);
				setUserTextCode('');
			}
		}
	}, [userTextCode]);

	const handleTextCodeChange = (event) => {
		setUserTextCode(event);
	};

	return (
		<div className='container'>
			<h4>
				הסרטון בו צפיתם עלה באמצעי תקשורת הנחשב ל"לא מסורתי". אילו אמצעי תקשורת
				מסורתיים ומודרניים אתם יכולים למצוא?
			</h4>
			<h5>
				מצאתם את אמצעי התקשורת ?<br></br>
				הכניסו את הקוד על מנת לקבל את הרמז הבא!
			</h5>
			<div className='ltr'>
				<VerificationInput
					validChars='0-9'
					inputProps={{ inputMode: 'numeric' }}
					value={userTextCode}
					onChange={handleTextCodeChange}
					length={8}
				/>
			</div>
			<img
				className='playlist'
				src='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/playlist-small.jpg'
				alt=''
			/>
		</div>
	);
};

export default Page3;
