import React, { useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';
import { NotificationManager } from 'react-notifications';
import QrScanner from '../components/QrScanner';

const Page5 = ({ updateStages }) => {
	const correctYears = ['2012', '2014', '2019', '2021', '2022', '2023'];
	const qrCode = 'https://qrcodes.pro/pqpC4o';

	const [openQrScanner, setOpenQrScanner] = useState(false);
	const [completeLevels, setCompleteLevel] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
	]);

	useEffect(() => {
		if (checkLevels()) {
			NotificationManager.success(
				'הצלחתם ועברתם לשלב הבא',
				'גאונים!',
				1500,
				setTimeout(() => {
					setOpenQrScanner(true);
				}, 1000)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [completeLevels]);
	const handleYearsComplete = (event, index) => {
		if (event === correctYears[index]) {
			setCompleteLevel((prevData) => {
				const newData = [...prevData];
				newData[index] = true;
				return newData;
			});
		}
	};
	const checkLevels = () => {
		return completeLevels.every(function (element) {
			return element === true;
		});
	};
	const handleScan = (scanComplete) => {
		if (scanComplete) {
			updateStages(5, true);
		}
	};

	return (
		<div className='container'>
			{openQrScanner ? (
				<QrScanner handleScan={handleScan} qrCode={qrCode} />
			) : (
				<div className='container-vertical'>
					<ul>
						{correctYears.map((item, index) => {
							return (
								<div key={index}>
									<li className='ltr overlay-li'>
										<VerificationInput
											validChars='0-9'
											inputProps={{ inputMode: 'numeric' }}
											onComplete={(event) => {
												handleYearsComplete(event, index);
											}}
											length={4}
										/>
										{completeLevels[index] ? (
											<div className='row'>
												<div className='overlay'>
													<img
														className='checkmark'
														src='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/checkmark.svg'
														style={{ height: 40 }}
														alt=''
													/>
												</div>
											</div>
										) : (
											''
										)}
									</li>
									<hr />
								</div>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default Page5;
