import React from 'react';

const Page9 = ({ updateStages }) => {
	const handleButtonClick = () => {
		updateStages(9, true);
	};

	return (
		<div className='container'>
			<img
				className='stand-image'
				src='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/stand-port.jpg'
				alt=''
			/>
			<button className='popup-btn' onClick={handleButtonClick}>
				הבא
			</button>
		</div>
	);
};

export default Page9;
