import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import VerificationInput from 'react-verification-input';

import QrScanner from '../components/QrScanner';

const Page7 = ({ updateStages }) => {
	const textCode = 'סיפור אישי';
	const pinCode = '1947';
	const qrCode = 'https://qrcodes.pro/KFlaZ9';

	const [userTextCode, setUserTextCode] = useState('');
	const [userPinCode, setUserPinCode] = useState('');
	const [pinCodeComplete, setPinCodeComplete] = useState(false);
	const [textCodeComplete, setTextCodeComplete] = useState(false);

	const handlePinChange = (event) => {
		setUserPinCode(event);
	};
	const handleTextCodeChange = (event) => {
		setUserTextCode(event.target.value);
	};

	const handleScan = (scanComplete) => {
		if (scanComplete) {
			updateStages(8, true);
		}
	};
	useEffect(() => {
		if (userTextCode.length === textCode.length) {
			if (userTextCode === textCode) {
				NotificationManager.success(
					'הצלחתם ועברתם לשלב הבא',
					'גאונים!',
					1500,
					setTimeout(() => {
						setTextCodeComplete(true);
					}, 1000)
				);
			} else {
				NotificationManager.error(
					'המחאה נגד ישראל צוברת תאוצה - מהרו לגלות את התשובה הנכונה!',
					'',
					1500
				);
				setUserTextCode('');
			}
		}
	}, [userTextCode]);

	useEffect(() => {
		if (userPinCode.length === pinCode.length) {
			if (userPinCode === pinCode) {
				NotificationManager.success(
					'הצלחתם ועברתם לשלב הבא',
					'גאונים!',
					1500,
					setTimeout(() => {
						setPinCodeComplete(true);
						// setOpenQrScanner(true);
					}, 1000)
				);
			} else {
				NotificationManager.error(
					'המחאה נגד ישראל צוברת תאוצה - מהרו לגלות את התשובה הנכונה!',
					'',
					1500
				);
				setUserPinCode('');
			}
		}
	}, [userPinCode]);
	return (
		<div className='container'>
			<h2 className='margin-top'>מה שם התחנה שאתם נמצאים בה עכשיו ?</h2>

			<div className='overlay-in'>
				<input
					type='text'
					className='text-input overlay-li'
					onChange={handleTextCodeChange}
					placeholder='**** ****'
					maxLength={10}
					disabled={textCodeComplete}
				/>
				{textCodeComplete ? (
					<img
						className='checkmark-no-overlay'
						src='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/checkmark.svg'
						style={{ height: 40 }}
						alt=''
					/>
				) : (
					''
				)}
			</div>
			{textCodeComplete && (
				<ul>
					<li>
						<img
							width={60}
							src='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/7-in-circle.png'
							alt=''
						/>
					</li>
					<li className='ltr overlay-li'>
						<VerificationInput
							validChars='0-9'
							inputProps={{ inputMode: 'numeric' }}
							value={userPinCode}
							onChange={handlePinChange}
							length={4}
						/>
						{pinCodeComplete && (
							<div className='row'>
								<div className='overlay'>
									<img
										className='checkmark'
										src='https://tevelbreakoutgame.s3.eu-north-1.amazonaws.com/public/checkmark.svg'
										style={{ height: 40 }}
										alt=''
									/>
								</div>
							</div>
						)}
					</li>
				</ul>
			)}
			{pinCodeComplete && (
				<div>
					<QrScanner handleScan={handleScan} qrCode={qrCode} />
				</div>
			)}
		</div>
	);
};

export default Page7;
