import React, { useState, useEffect } from 'react';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import '../Styles/qrScanner.css';
import { NotificationManager } from 'react-notifications';

const QrScanner = ({ handleScan, qrCode }) => {
	const TIMER = 30;
	const [qrIsScanning, setQrIsScanning] = useState(true);
	const [countResults, setCountResults] = useState(0);
	const [remainingTime, setRemainingTime] = useState(3);

	const onNewScanResult = (result) => {
		if (qrCode !== result) {
			setCountResults(countResults + 1);

			NotificationManager.error(
				'המחאה נגד ישראל צוברת תאוצה - מהרו לגלות את התשובה הנכונה!',
				'',
				1500
			);

			setQrIsScanning(false);
			setRemainingTime(TIMER);
			const timer = setInterval(() => {
				setRemainingTime((prevTime) => prevTime - 1);
			}, 1000);
			setTimeout(() => {
				clearInterval(timer);
				setQrIsScanning(true);
			}, 30000);
		} else {
			setQrIsScanning(false);
			NotificationManager.success(
				'הצלחתם ועברתם לשלב הבא',
				'גאונים!',
				1500,
				setTimeout(() => {
					handleScan(true);
				}, 1500)
			);
		}
	};

	return (
		<div className='qr-container'>
			{qrIsScanning ? (
				<Html5QrcodePlugin
					fps={10}
					qrbox={250}
					disableFlip={false}
					qrCodeSuccessCallback={onNewScanResult}
				/>
			) : (
				<>
					<h2>נסו שוב בעוד</h2>
					<h1>{remainingTime}</h1>
					<h2>שניות</h2>
				</>
			)}
		</div>
	);
};

// const QrScanner = ({ handleScan, qrCode }) => {
// 	const [qrIsScanning, setQrIsScanning] = useState(true);
// 	const [qrScanningTries, setQrScanningTries] = useState(2);
// 	const [lastResult , setLastResult] = useState('');

// 	useEffect(() => {
// 		const scanner = new Html5QrcodeScanner('reader', {
// 			qrbox: {
// 				width: 250,
// 				height: 250,
// 			},
// 			fps: 4,
// 		});

// 		const success = (result) => {
// 			if(qrIsScanning){
// 			if (qrCode === result) {
// 				scanner.clear();
// 				handleScan(true);
// 				NotificationManager.success(
// 					'הצלחתם ועברתם לשלב הבא',
// 					'גאונים!',
// 					1500,
// 					setTimeout(() => {
// 						handleScan(true);
// 					}, 1500)
// 				);
// 			} else {
// 				NotificationManager.error(
// 					'המחאה נגד ישראל צוברת תאוצה - מהרו לגלות את התשובה הנכונה!',
// 					'',
// 					1500
// 				);
// 				console.log('try again');

// 				setQrIsScanning(false);
// 				scanner
// 					.stop()
// 					.then((result) => {
// 						console.log(result);
// 					})
// 					.catch((error) => {
// 						console.log(error);
// 					});
// 			}
// 		};
// 		const error = (error) => {
// 			console.warn(error);
// 		};
// 		setTimeout(() => {
// 			scanner.render(success, error);

// 			setQrIsScanning(true);
// 		}, 3000);
// 	}, []);

// 	return (
// 		<div className='container qr-container'>
// 			{qrIsScanning ? <div id='reader'></div> : 'טוען...'}
// 		</div>
// 	);
// };

export default QrScanner;
